<template>
  <div>
    <div id="loader" style="display: none" class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div v-if="discussion.length > 0">
      <div class="discussion-item" v-for="(item, index) in discussion" :key="index">
        <!--- {{ item }} -->
        <p class="me-paragraph" v-if="item.type=='me'"> 
          <span v-html="item.text?.replaceAll(/\n/g,'</br>')"></span>
        </p>
        <p class="ai-paragraph" v-if="item.type=='ai'"> 
          <span v-html="item.text?.replaceAll(/\n/g,'</br>')"></span>
        </p>
      </div>
    </div>
    <div class="bottom-form">
      <form @submit.prevent="submitForm">
        <textarea v-model="inputText" rows="2"></textarea>
        <select id="selectModel" v-model="selectedModel">
          <!--<option enable="true">dolphin-mistral</option>
          <option>llama2</option> -->
          <option v-for="(option, index) in modelOptions"  :key="index" :value="option">{{option}}</option>
        </select>
        <button type="submit">send</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import appConfig from '../config'

export default {
  data() {
    return {
      inputText: '',
      selectedModel: null,
      modelOptions: [],
      discussion: [],
      context: []
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    showLoader() {
      document.getElementById("loader").style.display = 'block';
    },
    hideLoader() {
      document.getElementById("loader").style.display = 'none';
    },
    initialize() {
      this.showLoader()
      axios.get(appConfig.tagsApiUrl)
        .then((resp) => {
          resp.data.models?.forEach(element => {
            this.modelOptions.push(element.name);
          });
          setTimeout(() => {
            let select = document.getElementById("selectModel")
            select.selectedIndex = 0;
          }, 0);
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          console.error(error);
        });
    },
    submitForm() {
      this.showLoader();
      let dataIndex = 0;
      const data = {
        model: this.selectedModel
        ,prompt: this.inputText
        ,context: this.context
      }

      const axiosConfig = {
        responseType: 'text',
        onDownloadProgress: (progressEvent) => {
          if (dataIndex == 0) {
            this.discussion.push({
              type: "me",
              text: this.inputText
            });
            this.inputText = '';
          }
          const rawResponse = progressEvent.event.currentTarget.response;
          try {
            const responseObject = JSON.parse(rawResponse.substring(dataIndex));
            var responseElement = responseObject.response;
            if (dataIndex == 0) {
              this.discussion.push({
                type: "ai",
                text: responseElement
              });
            } else {
              this.discussion[this.discussion.length - 1].text += responseElement;
            }
            if (responseObject.done) {
              this.context = responseObject.context;
            }
            dataIndex = rawResponse.length;
          } catch (error) {
            console.error(error);
          }
        },
      }

      axios.post(appConfig.generateApiUrl, data, axiosConfig)
        .then(() => {
          this.hideLoader();
        })
        .catch((error) => {
          this.discussion.push({
              type: "ai",
              text: error.message
          });
          this.hideLoader();
          console.error(error);
        });
    }
  },
}
</script>
